import Funciones from '@/handler/funciones'

var items = {
  getItems () {
    var data = [
      {
        title: true,
        name: 'CONFIGURACIONES',
      },

      {
        name: 'Módulos',
        url: '/sa/modules/configurate',
        icon: 'fa fa-cube',
      },

      {
        name: 'Roles',
        url: '/sa/roles/configurate',
        icon: 'fa fa-shield',
      },

      {
        name: 'Parámetros',
        url: '/sa/parametrs/configurate',
        icon: 'fa fa-list',
      },

      {
        name: 'Configuración',
        url: '/sa/settings/configurate',
        icon: 'fa fa-cogs',
      },
    ]
    return data
  },
}

export default items
