import Helper from '@/handler/helper'
import Funciones from '@/handler/funciones'

var items = {
  getItems (arrItems) {

    var helperRouter = Helper.getIdentifyRouter()

    var menu = []
    var children = []
    var group = ''

    menu.push({
      name: 'Panel de Control',
      url: helperRouter + 'dashboard',
      icon: 'cui-dashboard',
    })

    /* ORDENO POR GRUPO */
    var arrTmp = []
    for (const prop in arrItems) {
      arrTmp.push(arrItems[prop])
    }
    arrItems = this.sortJSON(arrTmp, 'group', 'asc')    

    var index = 0
    children[index] = []
    for (const prop in arrItems) {
      if(group != arrItems[prop].group) {

        /* AGRUPO EN UN DROPDOWN */
        if(group!=''){
          index = index + 1
          children[index] = []
        }

        menu.push({
          name: arrItems[prop].group.substring(arrItems[prop].group.indexOf(".") + 1),
          url: arrItems[prop].router,
          icon: arrItems[prop].group_icon,
          children: children[index]
        })

        /* SI NO LO AGRUPO Y MUESTRO TODOS LOS ITEMS JUNTOS */
        /*menu.push({
          title: true,
          name: arrItems[prop].group,
        })*/

        group = arrItems[prop].group
      }

      /* SI NO LO AGRUPO Y MUESTRO TODOS LOS ITEMS JUNTOS */
      /*menu.push({
        name: arrItems[prop].title,
        url: helperRouter + arrItems[prop].router,
        icon: arrItems[prop].icon,
      })*/


      /* AGRUPO EN UN DROPDOWN */
      children[index].push({
        name: arrItems[prop].title,
        url: helperRouter + arrItems[prop].router,
        icon: arrItems[prop].icon,
      })      
    }

    // ORDENO LOS HIJOS
    menu.forEach(element => {
      if(element.children) {        
        element.children = this.sortJSON(element.children, 'name', 'asc')            
        
        element.children.forEach(element1 => {
          element1.name = element1.name.substring(element1.name.indexOf(".") + 1)
        });
      }      
    });

    return menu
  },
  sortJSON(data, key, orden) {
    return data.sort(function (a, b) {
        var x = parseInt(a[key].substr(0,a[key].indexOf(".",0))),
        y = parseInt(b[key].substr(0,b[key].indexOf(".",0)));

        if (orden === 'asc') {
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        }

        if (orden === 'desc') {
            return ((x > y) ? -1 : ((x < y) ? 1 : 0));
        }
    });
  },  
}

export default items
