import Funciones from '@/handler/funciones'

var items = {
  getItems () {
    var data = [
      {
        title: true,
        name: 'ACCESOS',
      },
      {
        name: 'Staff',
        url: '/a/staff/configurate',
        icon: 'fa fa-users',
      },

      {
        title: true,
        name: 'CONFIGURACION',
      },
      {
        name: 'Ajustes',
        url: '/a/settings/configurate',
        icon: 'fa fa-cogs',
      },
    ]
    return data
  },
}

export default items
