<template>
  <div class="app">

    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <b-link class="navbar-brand" to="#">
        <img class="navbar-brand-full containt-logo-custom-full" :src="logo" fluid :alt="proyectName" />
        <img class="navbar-brand-minimized containt-logo-custom-minimized" :src="favicon" fluid :alt="proyectName" />
      </b-link>
      <SidebarToggler class="d-md-down-none" display="lg" />

      <b-navbar-nav v-if="seeControls">
        <b-button v-if="arr.seccionOptions.length" 
                  @click="changeProfile()" 
                  type="button" 
                  variant="outline-dark" 
                  size="sm" 
                  class="btn-pill float-left ml-2 custom-container-change-profile" 
                  v-b-tooltip.hover title="Cambiar Perfil"
                  id="popover-container-change-profile">
          <i class="fa fa-exchange"></i>
        </b-button>
        <div v-else class="d-none d-lg-block">
          <div class="container-login-como">
            <b-badge variant="success">{{loginComo.profile}}</b-badge>
            {{this.loginComo.name}}<br>
            <small v-for="(value, index) in loginComo.subProfile" v-bind:key="index">          
              <div class="badge badge-pill badge-dark mr-1">{{value}}</div>
            </small>          
          </div>                  
        </div>

        <b-popover triggers="hover focus"                   
                  target="popover-container-change-profile">                            
          
          <div class="container-login-como">
            <b-badge variant="success">{{loginComo.profile}}</b-badge>
            {{this.loginComo.name}}<br>
            <small v-for="(value, index) in loginComo.subProfile" v-bind:key="index" class="small-container-change-profile">          
              <div class="badge badge-pill badge-dark mr-1">{{value}}</div>
            </small>          
          </div>        
        </b-popover>        
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto">   
        <DefaultHeaderDropdownAccnt :loginComo="loginComo"/>
        <b-button v-b-toggle.sidebar-aside
                v-if="seeAsside" 
                class="d-none d-lg-block siderbar-right asside-right-bar-custom" 
                v-b-tooltip.hover.lefttop 
                title="Despligue para mas funcionalidades">   
            <b-icon icon="collection-fill"></b-icon>
        </b-button>       
      </b-navbar-nav>
    </AppHeader>

    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader/>
        <SidebarForm/>
        <SidebarNav :navItems="nav"></SidebarNav>
        <SidebarFooter/>
        <SidebarMinimizer/>
      </AppSidebar>
      <main class="main">        
        <b-alert show variant="warning" class="alert-clear-cache" v-if="alert.cache.show">
          Ante cualquier problema, limpie la memoria cache. 
          <a href="javascript:void(0);" class="alert-link" @click="clearCache()">Limpiar Cache</a>
          <a href="javascript:void(0);" class="alert-link pull-right hide-clear-cache" title="Ocultar aviso" @click="hideClearCache()">
            <b-icon icon="toggle-on"></b-icon>
          </a>
        </b-alert>        

        <div class="container-fluid">
          <router-view :key="$route.path"></router-view>
        </div>
      </main>
      <AppAside fixed>
        <!--aside-->
        <DefaultAside v-if="seeAsside"/>
      </AppAside>
    </div>

    <TheFooter class="mt-3">
      <!--footer-->
      <div>
        <span class="mr-1">&copy; {{moment().format('YYYY')}} - Desarrollado por</span>
        <a :href="developer.url" target="_blank">{{this.developer.name}}</a>
      </div>
    </TheFooter>

    <b-modal v-model="modal.changePerfil.active"
             headerBgVariant="dark"
             bodyBgVariant="light"
             title="Cambiar Perfil"
             no-close-on-esc
             no-close-on-backdrop
             hide-header-close
             centered>

      <b-row>
        <b-col>
          <b-list-group>
            <b-list-group-item v-for="(item, index) in arr.seccionOptions"
                               :key="index"
                               @click="reloadProfile(item)"
                               href="#"
                               class="flex-column align-items-start container-list-group-item-custom"
                               v-b-tooltip.hover title="Click para cambiar la sessión del usuario">

              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{item.selectorSeccionName}}</h5>
                <b-badge variant="secondary" class="badge-selector-profile-custom" pill>{{ item.profileName }}</b-badge>
              </div>

            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>

      <template v-slot:modal-footer>
        <b-button type="button" variant="outline-secondary" @click="cancelProfile()">Cancelar</b-button>
      </template>

    </b-modal>
  </div>
</template>

<script>
import Session from '@/handler/session'
import Profiles from '@/config/profiles'
import Helper from '@/handler/helper'
import MenuSuperadmin from '@/components/containers/menuSuperadmin'
import MenuAdmin from '@/components/containers/menuAdmin'
import Menu from '@/components/containers/menu'
import { Header as AppHeader, SidebarToggler, Sidebar as AppSidebar, SidebarFooter, SidebarForm, SidebarHeader, SidebarMinimizer, SidebarNav, Aside as AppAside, AsideToggler, Footer as TheFooter, Breadcrumb } from '@coreui/vue'
import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt'
import DefaultAside from './DefaultAside'
import {EventBus} from '@/handler/event-bus'
import serviceAPI from './services'
import Error from '@/handler/error'
import Storage from '@/handler/storageSession'

export default {
  name: 'DefaultContainer',
  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    DefaultAside,
    TheFooter,
    Breadcrumb,
    DefaultHeaderDropdownAccnt,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer,
  },
  data () {
    return {
      proyectName: '',
      logo: '',
      favicon: '',
      colorTitleMenu: '',
      colorSelectMenu: '',
      primaryColor: '',
      nav: [],
      seeControls: false,
      seeAsside: false,
      modal: {
        changePerfil: {
          active: false,
        }
      },
      arr: {
        seccionOptions : []
      },
      loginComo: {
        profile: '',
        subProfile: [],
        name: '',
      },
      developer: {
        name: '',
        url: ''
      },
      alert:{
        cache: {
          show: false
        }
      }
    }
  },
  computed: {
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched.filter((route) => route.name || route.meta.label )
    },
    isSeller(){
      if(Helper.getSeller()) {
        return true
      } else {
        return false
      }
    },
    isEmployee(){
      if(Helper.getEmployee()) {
        return true
      } else {
        return false
      }
    },  
  },
  created () {
    EventBus.$on('Settings:settings', data => {
      this.setup()
    })
  },
  mounted() {
    this.setup()
    this.statusClearCache()
  },    
  methods: {
    setup () {      
      if(Session.getSession().settings) {
        this.proyectName = Session.getSession().settings.proyect_name
        this.logo = Session.getSession().settings.logo
        this.favicon = Session.getSession().settings.favicon
        this.colorTitleMenu = Session.getSession().settings.color_title_menu
        this.colorSelectMenu = Session.getSession().settings.color_select_menu
        this.primaryColor = Session.getSession().settings.color_primary

        this.createCSS()
        this.configMenu()
        this.configLoginComo()
        this.configSelectProfile()
        this.configDeveloper()
      }
    },
    createCSS() {
      var hoja = document.createElement('style')
      hoja.innerHTML = ""
      hoja.innerHTML = hoja.innerHTML  + ".nav-title { background: " + this.colorTitleMenu + "; }"
      hoja.innerHTML = hoja.innerHTML  + ".sidebar .nav-link:hover { background: " + this.colorSelectMenu + " !important; }"
      hoja.innerHTML = hoja.innerHTML  + ".siderbar-right { background: " + this.colorTitleMenu + " !important; padding: 11px !important; border-radius: 0px !important; color: #fff; font-size: 20px;}"
      hoja.innerHTML = hoja.innerHTML  + ".sidebar .nav-link.active .nav-icon { color: " + this.primaryColor + "}"
      hoja.innerHTML = hoja.innerHTML  + ".sidebar-minimized .sidebar .nav-item:hover > .nav-link  { background: " + this.colorSelectMenu + "}"
      hoja.innerHTML = hoja.innerHTML  + ".b-pagination .page-item.active .page-link { background-color: " + this.primaryColor + "; border-color: " + this.primaryColor + "; }"
      hoja.innerHTML = hoja.innerHTML  + "a {color: " + this.primaryColor + ";}"
      hoja.innerHTML = hoja.innerHTML  + "a:hover {color: " + this.primaryColor + "; filter: brightness(85%);}"
      hoja.innerHTML = hoja.innerHTML  + ".selected-custom {color: " + this.primaryColor + ";}"
      hoja.innerHTML = hoja.innerHTML  + ".selected-custom:hover {color: " + this.primaryColor + "; filter: brightness(85%);}"
      hoja.innerHTML = hoja.innerHTML  + ".btn-link {color: " + this.primaryColor + ";}"
      hoja.innerHTML = hoja.innerHTML  + ".btn-link:hover {color: " + this.primaryColor + "; filter: brightness(85%);}"
      hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover {color: black !important;}"      
      hoja.innerHTML = hoja.innerHTML  + ".asside-right-bar-custom {background: " + this.primaryColor + ";}" 
      hoja.innerHTML = hoja.innerHTML  + ".aside-menu .nav-tabs .nav-link.active {color: " + this.primaryColor + ";}"
      
      document.body.appendChild(hoja);
    },
    configMenu() {
      if(Session.getSession().auth) {
        if(Session.getSession().auth.user.profile_id == Profiles.SUPERADMIN) {
          this.nav = MenuSuperadmin.getItems()
          this.seeControls = false
          this.seeAsside = false
        } else {
          if(Session.getSession().auth.user.profile_id == Profiles.PERSONAL &&
             Session.getSession().auth.user.rol_admin) {
            this.nav = MenuAdmin.getItems()
            this.seeControls = false
            this.seeAsside = false
          } else {
            this.nav = Menu.getItems(Session.getSession().auth.user.permissions.menu)
            this.seeControls = true

            if(this.isSeller || this.isEmployee) {
              this.seeAsside = false
            } else {
              this.seeAsside = true
            }
          }
        }
        
        if(Session.getSession().auth.user.profile_id == Profiles.CLIENTE) {
          this.seeAsside = false
        }
        if(Session.getSession().auth.user.profile_id == Profiles.PROVEEDOR) {
          this.seeAsside = false
        }
      }
    },
    configLoginComo() {
      if(Session.getSession().auth) {
        if(Session.getSession().auth.user.profile_default) {

          if(Session.getSession().auth.user.profile_default.id == Profiles.PERSONAL) {
            this.loginComo.profile = "Staff"

            if(Helper.getEmployee()) {              
              this.loginComo.subProfile.push(Helper.getEmployee().category.name)
            }
            
            if(Helper.getSeller()) {              
              if(!this.loginComo.subProfile.length) {
                this.loginComo.subProfile.push('Comercial') 
              }              
            }
          }
          if(Session.getSession().auth.user.profile_default.id == Profiles.CLIENTE) {
            this.loginComo.profile = "Cliente"
          }
          if(Session.getSession().auth.user.profile_default.id == Profiles.PROVEEDOR) {
            this.loginComo.profile = "Proveedor"
          }

          if (!Session.getSession().auth.user.selector_session){
            if(Session.getSession().auth.user.profile_id!=Profiles.SUPERADMIN) {
              if(Session.getSession().auth.user.rol_admin==false) {
                this.modal.changePerfil.active = true
              }
            }
          } else {
            this.loginComo.name = Session.getSession().auth.user.selector_session_name
          }
        }
      }
    },
    configSelectProfile() {
      var arrayProfiles = []
      if(Session.getSession().auth) {
        var permission = Session.getSession().auth.user.profile_access
        for (const prop in permission.staff) {
          arrayProfiles.push({
            profileId: Profiles.PERSONAL,
            profileName: 'Staff',
            selectorSeccion: permission.staff[prop].id,
            selectorSeccionName: permission.staff[prop].name
          })
        }
        for (const prop in permission.customers) {
          arrayProfiles.push({
            profileId: Profiles.CLIENTE,
            profileName: 'Cliente',
            selectorSeccion: permission.customers[prop].id,
            selectorSeccionName: permission.customers[prop].name
          })
        }
        for (const prop in permission.suppliers) {
          arrayProfiles.push({
            profileId: Profiles.PROVEEDOR,
            profileName: 'Proveedor',
            selectorSeccion: permission.suppliers[prop].id,
            selectorSeccionName: permission.suppliers[prop].name
          })
        }
        this.arr.seccionOptions = arrayProfiles
      }
    },
    changeProfile() {
      var hoja = document.createElement('style')
      hoja.innerHTML = ""
      hoja.innerHTML = hoja.innerHTML  + ".container-list-group-item-custom:hover { background: " + this.primaryColor + "; color: white;}"
      document.body.appendChild(hoja);
      this.modal.changePerfil.active = true
    },
    reloadProfile(item) {
      let loader = this.$loading.show();
      var result = serviceAPI.configSession(item);

      result.then((response) => {

        var result_1 = serviceAPI.getSessionUser();
        result_1.then((response_1) => {
          var data = response_1.data

          Session.setSessionAuth(data)
          this.$router.push({ name: 'Init' })
          this.modal.changePerfil.active = false

          loader.hide()
        })
        .catch(error_1 => {
          loader.hide()
          this.$awn.alert(Error.showError(error_1))
        })

      })
      .catch(error => {
        loader.hide()
        this.$awn.alert(Error.showError(error));
      })
    },
    cancelProfile(){
      if (!Session.getSession().auth.user.selector_session){
        this.modal.changePerfil.active = true
        this.$awn.alert("No se cargo el perfil por defecto")
      } else {
        this.modal.changePerfil.active = false
      }
    },
    configDeveloper() {
      if(Session.getSession().auth) {
        if(Session.getSession().auth.developer) {
          this.developer.name = Session.getSession().auth.developer.name
          this.developer.url = Session.getSession().auth.developer.url
        }
      }
    },

    // CACHE
    clearCache() {
      this.$router.push({ name: 'Clear'}).catch(err => {
          
          // Ignore the vuex err regarding  navigating to the page they are already on.
          if ( err.name !== 'NavigationDuplicated' &&
              !err.message.includes('Avoided redundant navigation to current location')) {
          
          // But print any other errors to the console
              logError(err);
          }
      });           
    },        
    statusClearCache() {              
      if(Storage.getValue('show_alert_clear_cache') != null) {        
        if( Storage.getValue('show_alert_clear_cache') == 'false' ) {
          this.alert.cache.show = false
        } else {
          this.alert.cache.show = true
        }
      } else {
        this.alert.cache.show = true
      }
    },      
    hideClearCache() {
      this.alert.cache.show = false
      Storage.setValue('show_alert_clear_cache', false)
    }
  }
}
</script>

<style>  
  body {
    background-color: #e4e5e6 !important;    
  }
  .sidebar-minimized .sidebar .nav > .nav-dropdown:hover {
    background: #2f353a;
  }
  .nav-dropdown-items {
    background: #00000033;
  }
  .main .container-fluid {
    padding: 0 15px;
    margin-top: 15px;
  }
  .siderbar-right:hover {
    background: #c8ced3 !important;
    color: #fff;
  }
  .card {
    margin-bottom: 10px;
  }
  .table th, .table td {
    padding: 5px;
  }
  .container-login-como {
    font-weight: bold;
  }
  .container-login-como span {
    font-size: 15px;
  }
  .vld-overlay.is-full-page {
    z-index: 99999 !important;
    background-color: #00000054 !important;
  }
  .btn-sm, .btn-group-sm > .btn {
    padding: 0.15rem 0.5rem;
  }
  .modal-header {
    font-weight: 500;
    font-size: 17px;
  }
  .material-icons {
    font-size: 18px;
  }
  .badge-selector-profile-custom {
    padding-top: 0.6em;
  }
  legend {
    font-weight: 600;
  }
  @media (min-width: 992px){
    .sidebar-minimized .sidebar section :not(.nav-dropdown-items) > .nav-item:last-child::after {
      display: none;
    }
  }
  .module-help-information {
    cursor: pointer;
  }
  
  .widget-custom .card-header{
    padding: 0px;
  }
  .widget-custom .card-body{
    padding: 5px;
  }
  .widget-header-custom {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .widget-info-custom {
    padding-top: 4px;
    padding-bottom: 2px;
    padding-left: 8px;
    padding-right: 8px;
    background: white;
    color: black;
    border-radius: 50%;
    cursor: pointer;
  }
  .widget-ultima-actualizacion {
    cursor: pointer;
  }
  .widget-ultima-actualizacion span {
    background: #efefef;
    padding-left: 5px;
    padding-right: 5px;
  }
  .card-footer {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .widget-router-custom {
    position: absolute;
    left: 1px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 10px;
    bottom: 1px;    
  }
  .custom-container-change-profile {
    line-height: 2;
  }
  .small-container-change-profile {
    font-size: 15px;
  }
  .containt-logo-custom-minimized {
    object-fit: contain;
    width: 48px;
    height: 48px;
  }
  .containt-logo-custom-full {
    object-fit: contain;
    width: 150px;
    height: 48px;
  }
</style>
<style scoped>
  .alert-clear-cache {
    padding: 0px;
    padding-left: 1.25rem;
    font-size: 10px;
    margin-bottom: 0px;    
  }
  .alert-clear-cache .hide-clear-cache {      
    padding-right: 1.25rem;
    font-size: 15px;
    line-height: 0;      
  }
</style>