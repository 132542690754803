<template>
  <AppHeaderDropdown right no-caret>
    <template slot="header">      
      <b-avatar :src="user.picture"
                :alt="user.name" 
                v-b-tooltip.hover.lefttop :title="nameUser"
                v-if="user.picture">
      </b-avatar>                  
      <b-avatar v-else
                :icon="user.icon"
                :alt="user.name" 
                variant="dark"
                v-b-tooltip.hover.lefttop :title="user.name + ' - ' + user.email">
      </b-avatar>  
    </template>
    <template slot="dropdown">
      <b-dropdown-item v-on:click="clearCache">        
        <b-icon icon="eraser-fill"></b-icon> Limpiar Cache
      </b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item v-on:click="logout">
        <b-icon icon="lock"></b-icon> Logout
      </b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import serviceAPI from './services'
import Session from '@/handler/session'
import {EventBus} from '@/handler/event-bus'
import Error from '@/handler/error'
import Perfil from '@/config/profiles'
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue'

export default {
  name: 'DefaultHeaderDropdownAccnt',
  components: {
    AppHeaderDropdown,
  },
  props: {
    loginComo: [Object, Array],
  },
  data: () => {
    return {
      user : {
        name : '',
        picture : '',
        icon: '',
        email : '',        
      },
    }
  },
  created () {
    EventBus.$on('Auth:auth', data => {
      this.setup()
    })
  },
  mounted() {
    this.setup()
  },
  computed: {
    nameUser() {
      var name = this.user.name + ' - ' + this.user.email
      var subprofile = ''
      if(this.loginComo) {
        if(this.loginComo.subProfile) {
          this.loginComo.subProfile.forEach(element => {
            subprofile = subprofile + element + ","
          });
        }
        if(subprofile) {
          return this.loginComo.profile + ' - (' + subprofile.slice(0, -1) + ') - ' + name
        } else {
          return this.loginComo.profile + ' - ' + name
        }
        
      } else {
        return name
      }
    }
  },
  methods: {
    setup () {      
      if(Session.getSession().auth) {
        var profile_id = Session.getSession().auth.user.profile_id
        var selector_session = Session.getSession().auth.user.selector_session

        if( profile_id == Perfil.PERSONAL) {                  
          var result = serviceAPI.showStaff(selector_session)

          result.then((response) => {
            this.user.picture = response.data.image
            this.user.icon = 'person-fill'
          })
        }

        if(profile_id == Perfil.CLIENTE) {
          var result = serviceAPI.showCustomers(selector_session)

          result.then((response) => {
            this.user.picture = response.data.image
            this.user.icon = 'building'
          })
        }

        if(profile_id == Perfil.PROVEEDOR) {
          var result = serviceAPI.showSuppliers(selector_session)

          result.then((response) => {
            this.user.picture = response.data.image
            this.user.icon = 'building'
          })
        }

        this.user.name = Session.getSession().auth.user.name
        this.user.email = Session.getSession().auth.user.email
      }
    },
    logout () {
      Session.removeSessionAuth()
    },

    // CACHE
    clearCache() {
      this.$router.push({ name: 'Clear'}).catch(err => {
          
          // Ignore the vuex err regarding  navigating to the page they are already on.
          if ( err.name !== 'NavigationDuplicated' &&
              !err.message.includes('Avoided redundant navigation to current location')) {
          
          // But print any other errors to the console
              logError(err);
          }
      });           
    },      
  },
}
</script>
